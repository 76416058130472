<template>
  <div class="breadcrumbs">
    <div class="item" v-for="item in breadcrumbs" :key="item.title">
      {{ item.title }}
    </div>
    <div class="item">
      {{ data }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "area"],
  filters: {},
  computed: {},
  data() {
    return {
      breadcrumbs: [],
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.breadcrumbs = [
        {
          title: this.area,
        },
        {
          title: "/",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #2c2c2c;
  margin-bottom: 40px;
  margin-top: 20px;

  .item {
    margin-right: 15px;

    & + .item {
      margin-left: 15px;
      color: #fd3064;
    }

    &:nth-child(2) {
      margin: 0;
      color: #2c2c2c;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  .breadcrumbs {
    font-size: 17px;
    margin-bottom: 20px;
  }
}
</style>
